<div class="mx-2">
    <div class="float-end text-dark p-2 fs-5 text-center fw-semibold rounded-0 mw-500px">
        <div class="d-flex" style="align-items: center;">
            <!-- <div class="px-2 pe-5">All</div>
            <div class="p-1">
                <div class="form-check form-check-solid form-switch fv-row mb-0"><input type="checkbox"
                        id="allowmarketing" class="form-check-input w-30px h-15px mt-1" #admins checked (change)="getAdmins()"><label _ngcontent-tyk-c140=""
                        class="form-check-label me-2">Active</label></div>
            </div> -->
             <!-- <div class="px-2">Active</div>  -->
            <smart-button #exportexcelbutton id="xlsxBtn"><i
                class='fas fa-file-export fs-7 text-dark '></i>Excel</smart-button>
        </div>
    </div>
    <smart-grid [grouping]="grouping" [behavior]="behavior" [paging]="paging" [pager]="pager" [header]="header"
        [layout]="layout" [sorting]="sorting" [filtering]="filtering" [dataSource]="contactsgridsource"
        [columns]="columns" [grouping]="grouping" [appearance]="appearance" #contactsgrid id="contactsgrid"
        style="height: calc(100vh - 300px);" class="w-100"></smart-grid>
    <div class="float-end mt-0">Total Number of Records in Table: {{members.length}}</div>
</div>
