import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataAdapter, GridComponent, Smart } from 'smart-webcomponents-angular/grid';
import { MembersService } from 'src/app/services/members.service';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { DateTimePickerComponent } from 'smart-webcomponents-angular/datetimepicker';

@Component({
  selector: 'app-location-mapping',
  templateUrl: './location-mapping.component.html',
  styleUrls: ['./location-mapping.component.scss']
})
export class LocationMappingComponent implements OnInit {
  @ViewChild('benificiariesgrid', { read: GridComponent, static: false }) benificiariesgrid!: GridComponent;
  @ViewChild('volunteersgrid', { read: GridComponent, static: false }) volunteersgrid!: GridComponent;
  @ViewChild('datetimepicker3', { read: DateTimePickerComponent, static: false }) datetimepicker3!: DateTimePickerComponent;
  @ViewChild('#datetimepicker2', { read: DateTimePickerComponent, static: false }) datetimepicker2!: DateTimePickerComponent;

  token_response: any = {};
  token: any;
  locationId: any = "";
  locationsList: any = [];
  volunteersLen: any = 0;
  participantsLen: any = 0;
  mileRadius: number;
  startDate: any = "";
  endDate: any = "";
  nextMonday: any = '';
  matchingWeekDifference: number = 8;
  btnSave:boolean = false;
  constructor(public router: Router, private memberService: MembersService, private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    public toastralert: ToastralertService) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  ngOnInit(): void {
    this.matchingWeekDifference = this.token_response.MatchingWeekDifference;
    this.mileRadius = this.token_response.MatchingDefaultMiles;
    console.log(this.token_response)
    this.get_Locations();
    this.get_NextMonday();
    this.get_Next8WeeksfromStartDate();
  }

  get_NextMonday() {
    let currentDate = new Date();
    let currentDayOfWeek = currentDate.getDay();
    let daysUntilNextMonday = (8 - currentDayOfWeek) % 7;
    this.nextMonday = new Date();
    this.nextMonday.setDate(currentDate.getDate() + daysUntilNextMonday);
    this.startDate = new DatePipe('en-US').transform(this.nextMonday, 'MM/dd/yyyy');
    console.log('Next Monday:', this.startDate);
  }

  get_Next8WeeksfromStartDate() {
    const endDate = new Date(this.nextMonday.getTime());
    endDate.setDate(this.nextMonday.getDate() + (this.matchingWeekDifference * 7));
    this.endDate = new DatePipe('en-US').transform(endDate, 'MM/dd/yyyy');
  }

  get_Locations() {
    this.spinner.show();
    this.memberService.GetVolunteerLocationsByTenantId().subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.locationsList = results;
        console.log(results);
        this.spinner.hide();
      }
    },
      (err) => {
        this.spinner.hide();
        this.toastralert.toastrerrorbottom("Unable to get details.Please try again after some time.");
      }
    );
  }

  // get_volunteersByLocationId(event: any) {
  //   this.spinner.show();
  //   this.volunteersgridsource = [];
  //   this.benificiariesgridsource = [];
  //   this.participantsLen = 0;
  //   this.volunteersLen = 0;
  //   this.memberService.get_VolunteersByLocationId(event).subscribe((results) => {
  //     if (results != null && results != 'undefined') {
  //       this.volunteersLen = results.length;
  //       this.volunteergrid.dataSource = results;
  //       this.volunteersgridsource = results;
  //       this.spinner.hide();
  //       console.log(results);

  //     }
  //     if (results.length == 0) {
  //       this.spinner.hide();
  //       this.toastralert.toastrerrorbottom("There is no volunteers surrounded by this selected location");

  //     }
  //   },
  //     (err) => {
  //       this.spinner.hide();
  //       this.toastralert.toastrerrorbottom("Unable to get Volunteers.Please try again after some time.");
  //     }
  //   );
  // }

  GetParticipantsByRadius() {
    if (this.locationId == "" || this.locationId == null || this.locationId == undefined) {
      this.toastralert.toastrerrorbottom("Please select location");
      return false;
    }
    else if (this.mileRadius == null || this.mileRadius == undefined) {
      this.toastralert.toastrerrorbottom("Please enter mile radius");
      return false;
    }
    if (this.startDate == "" || this.startDate == null || this.startDate == undefined) {
      this.toastralert.toastrerrorbottom("Please select start date");
      return false;
    }
    else if (this.endDate == "" || this.endDate == null || this.endDate == undefined) {
      this.toastralert.toastrerrorbottom("Please select end date");
      return false;
    }
    else {
      this.GetPreMatchingListByTenantId();
    }
  }

  GetPreMatchingListByTenantId() {
    this.spinner.show();
    this.benificiariesgridsource = [];
    this.participantsLen = 0;
    let saveStartDate = this.datePipe.transform(this.startDate, 'MM/dd/yyyy');
    let saveEndDate = this.datePipe.transform(this.endDate, 'MM/dd/yyyy');
    this.memberService.GetPreMatchingListByTenantId(this.locationId, this.mileRadius, saveStartDate, saveEndDate).subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.participantsLen = results.length;
        this.benificiariesgrid.dataSource = results;
        this.benificiariesgridsource = results;
        this.spinner.hide();
        console.log(results);
      }
      if (results.length == 0) {
        this.spinner.hide();
        this.toastralert.toastrerrorbottom("There is no records for this location");
      }
    },
      (err) => {
        this.spinner.hide();
        this.toastralert.toastrerrorbottom("Unable to get Participants.Please try again after some time.");
      }
    );
  }

  // GetParticipantLocationsByLocationId() {
  //   this.spinner.show();
  //   this.benificiariesgridsource = [];
  //   this.volunteersgridsource = [];
  //   this.participantsLen = 0;
  //   this.volunteersLen = 0;
  //   let saveStartDate = this.datePipe.transform(this.startDate, 'MM/dd/yyyy');
  //   let saveEndDate = this.datePipe.transform(this.endDate, 'MM/dd/yyyy');
  //   this.memberService.GetParticipantLocationsByLocationId(this.locationId, this.mileRadius, saveStartDate, saveEndDate).subscribe((results) => {
  //     if (results != null && results != 'undefined') {
  //       this.participantsLen = results.length;
  //       this.benificiariesgrid.dataSource = results;
  //       this.benificiariesgridsource = results;
  //       this.spinner.hide();
  //       console.log(results);
  //     }
  //     if (results.length == 0) {
  //       this.spinner.hide();
  //       this.toastralert.toastrerrorbottom("There is no participants surrounded by this location");
  //     }
  //   },
  //     (err) => {
  //       this.spinner.hide();
  //       this.toastralert.toastrerrorbottom("Unable to get Participants.Please try again after some time.");
  //     }
  //   );
  // }

  onKeyDown(event: any) {
    const allowedKeys = ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'ArrowUp', 'ArrowLeft', 'ArrowRight'];
    const inputValue = event.target.value;
    const cursorPosition = event.target.selectionStart;

    if (!allowedKeys.includes(event.key) && !this.isNumericInput(event) && !this.isAllowedInput(inputValue, cursorPosition, event.key)) {
      event.preventDefault();
    }
  }

  isNumericInput(event: any): boolean {
    const key = event.key;
    return /\d/.test(key);
  }

  isAllowedInput(inputValue: string, cursorPosition: number, key: string): boolean {
    if (key === '-') {
      return cursorPosition === 0 && inputValue.indexOf('-') === -1;
    }
    return false;
  }

  GetVolunteersByParticipantLocationId(event: any) {
    this.spinner.show();
    this.volunteersgridsource = [];
    this.volunteersLen = 0;
    this.memberService.GetVolunteersByParticipantLocationId(event).subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.volunteersLen = results.length;
        this.volunteergrid.dataSource = results;
        this.volunteersgridsource = results;
        this.spinner.hide();
        console.log(results);

      }
      if (results.length == 0) {
        this.spinner.hide();
        this.toastralert.toastrerrorbottom("There is no volunteers surrounded by this selected location");

      }
    },
      (err) => {
        this.spinner.hide();
        this.toastralert.toastrerrorbottom("Unable to get Volunteers.Please try again after some time.");
      }
    );
  }

  // GetParticipantLocationsByVolunteerId(event: any) {
  //   this.spinner.show();
  //   this.benificiariesgridsource = [];
  //   this.participantsLen = 0;
  //   this.memberService.GetParticipantLocationsByVolunteerId(event).subscribe((results) => {
  //     if (results != null && results != 'undefined') {
  //       this.participantsLen = results.length;
  //       this.benificiariesgrid.dataSource = results;
  //       this.benificiariesgridsource = results;
  //       this.spinner.hide();
  //       console.log(results);
  //     }
  //     if (results.length == 0) {
  //       this.spinner.hide();
  //       this.toastralert.toastrerrorbottom("There is no participants surrounded by this selected volunteer location");
  //     }
  //   },
  //     (err) => {
  //       this.spinner.hide();
  //       this.toastralert.toastrerrorbottom("Unable to get Participants.Please try again after some time.");
  //     }
  //   );
  // }

  SaveMatchingStatus() {
    this.btnSave = true;
    let matchingStatusList:any = [];
    this.benificiariesgrid.getSelectedRows().then((rows) => {
      console.log(rows.length);
      if(rows.length > 0){
        rows.forEach((item: any) => {
          console.log(item[1]);
          let model = {
            CRMBeneficiaryId: item[1].CRMBeneficiaryId,
            CRMBeneficiaryFullName: item[1].CRMBeneficiaryFullName,
            PreferredGender: item[1].PreferredGender,
            Distance: item[1].Distance,
            CRMVolunteerId: item[1].CRMVolunteerId,
            CRMVolunteerFullName: item[1].CRMVolunteerFullName,
            AvailableDay: item[1].AvailableDay,
            AvailableDayId: item[1].AvailableDayId,
            AvailableStartTime: item[1].AvailableStartTime,
            AvailableEndTime: item[1].AvailableEndTime,
            DateIntake: item[1].DateIntake
          }
          matchingStatusList.push(model);
        })
        let sectionorder = {
          CRMPersonId: this.token_response.CRMPersonId,
          TenantId: this.token_response.tenantId,
          MatchingStatusModelList: matchingStatusList
        };
        console.log(sectionorder);
        this.memberService.SaveMatchingStatus(sectionorder).subscribe((results) => {
          if (results != null && results != "" && results != 'undefined') {
            this.toastralert.toastersuccesstop('Matched successfully');
            this.btnSave = false;
            // this.GetPreMatchingListByTenantId();
            this.router.navigate(['matchStatus']);
          }
          else {
            this.toastralert.toastrerror('Error occurred while saving matching data');
            this.btnSave = false;
          }
        },
          (err) => {
            this.toastralert.toastrerrorbottom("Unable to save matching data.Please try again after some time.");
            this.btnSave = false;
          }
        );
      }
      else{
        this.toastralert.toastrerrorbottom("Please select atleast one matched record");
        this.btnSave = false;
      }
    })
  }

  onRowDoubleClick(event: any) {
    const detail = event.detail,
      row = detail.row;
    this.GetVolunteersByParticipantLocationId(row.data.CRMCustomerId);
  }

  appearance = {
    alternationStart: 0,
    alternationCount: 2,
    allowSortAnimation: true
  }

  filtering = {
    enabled: true,
    filterRow: {
      visible: true
    }
  }

  sorting = {
    enabled: true,
    mode: 'many'
  }

  grouping = {
    enabled: true,
    renderMode: 'compact',
    groupBar: {
      visible: true
    }
  }

  layout: object = {
    rowHeight: 35
  }

  behavior = {
    allowColumnReorder: true
  }

  pager = {
    visible: true
  }

  paging = {
    enabled: true,
    pageSize: 100
  }

  header = {
    visible: true,
    buttons: ['search'],
  }

  volappearance = {
    alternationStart: 0,
    alternationCount: 2,
    allowSortAnimation: true
  }

  volfiltering = {
    enabled: true,
    filterRow: {
      visible: true
    }
  }

  volsorting = {
    enabled: true,
    mode: 'many'
  }

  volgrouping = {
    enabled: true,
    renderMode: 'compact',
    groupBar: {
      visible: true
    }
  }

  vollayout: object = {
    rowHeight: 35
  }

  volbehavior = {
    allowColumnReorder: true
  }

  volpager = {
    visible: true
  }

  selection = {
    enabled: true,
    mode: 'many',
    // allowRowSelection: true,
    action: 'none',
    checkBoxes: {
      selectAllMode: 'none',
      enabled: true,
      position: 'near',
    }
  };


  volpaging = {
    enabled: true,
    pageSize: 100
  }

  volheader = {
    visible: true,
    buttons: ['search'],

  }


  volunteergrid: DataAdapter = new window.Smart.DataAdapter(
    {
      dataSource: [],
      dataSourceType: 'json',
      dataFields: [
        'VolunteerFirstName: string',
        'VolunteerLastName: string',
        'Gender: string',
        'PrimaryCity: string',
        'AddressState: string',
        'PrimaryZipCode: string',
        'CRMCustomerId:string',
      ]
    })

  volunteersgridsource: any = this.volunteergrid;
  volunteercolumns =
    [
      { label: 'First Name', width: '10%', minWidth: 110, dataField: 'VolunteerFirstName', align: 'center', columnGroup: 'name' },
      { label: 'Last Name', width: '10%', minWidth: 100, dataField: 'VolunteerLastName', align: 'center', columnGroup: 'name' },
      { label: 'Gender', width: '18%', minWidth: 100, dataField: 'Gender', align: 'center', columnGroup: 'name' },
      { label: 'City', width: '10%', minWidth: 100, dataField: 'PrimaryCity', align: 'center', columnGroup: 'name' },
      { label: 'State', width: '10%', minWidth: 100, dataField: 'AddressState', align: 'center', columnGroup: 'name' },
      { label: 'Zipcode', width: '10%', minWidth: 100, dataField: 'PrimaryZipCode', align: 'center', columnGroup: 'name' },
      { label: 'CRMCustomerId', width: '10%', minWidth: 100, dataField: 'CRMCustomerId', align: 'center', columnGroup: 'name', visible: false },

    ]

  benificiariegrid: DataAdapter = new window.Smart.DataAdapter(
    {
      dataSource: [],
      dataSourceType: 'json',
      dataFields: [
        'CRMBeneficiaryId: string',
        'CRMBeneficiaryFullName: string',
        'CRMVolunteerId: string',
        'CRMVolunteerFullName: string',
        'AvailableDay:string',
        'AvailableStartTime: string',
        'AvailableEndTime:string',
        'AvailableDayId:string',
        'DateIntake:string',
        'PreferredGender:string',
        'Distance:string',
      ]
    })

  benificiariesgridsource: any = this.benificiariegrid;
  benificiariescolumns =
    [
      { label: 'CRMBeneficiaryId', width: '15%', dataField: 'CRMBeneficiaryId', align: 'center', columnGroup: 'name', visible: false },
      { label: 'Beneficiary Name', width: '15%', dataField: 'CRMBeneficiaryFullName', align: 'center', columnGroup: 'name' },
      {
        label: 'Date Intake', width: '10%', dataField: 'DateIntake', align: 'center', columnGroup: 'name', formatFunction: function (settings: any) {
          const formatedDate = settings.value != null ? new Smart.Utilities.DateTime(settings.value).toString('MM/dd/yyyy') : "";
          settings.template = formatedDate;
        },
      },
      { label: 'Preferred Gender', width: '15%', dataField: 'PreferredGender', align: 'center', columnGroup: 'name' },
      {
          label: 'Distance', width: '13%', dataField: 'Distance', align: 'center', columnGroup: 'name',
          formatFunction: function (settings: any) {
            let distance = settings.value != null ? settings.value : 0.000;
            let color = "#FFD700";
            if (distance <= 1) {
              color = "#6B1CB0";
            }
            else if (distance > 1 && distance <= 1.5) {
              color = "#006400";
            }
            else {
              color = "#ff0000";
            }
            const formatedDate = '<span style="margin-right: 7px; padding: 4px 18px; border-radius: 10px 5px; color: #fff; background: '+ color +'";>' + distance + '</span>';
            settings.template = formatedDate;
          },
      },
      { label: 'CRMVolunteerId', width: '15%', dataField: 'CRMVolunteerId', align: 'center', columnGroup: 'name', visible: false },
      { label: 'Volunteer Name', width: '15%', dataField: 'CRMVolunteerFullName', align: 'center', columnGroup: 'name' },
      { label: 'Day', width: '10%', dataField: 'AvailableDay', align: 'center', columnGroup: 'name' },
      // {
      //   label: 'Distance', width: '10%', dataField: 'Distance', align: 'center', columnGroup: 'name',
      //   formatFunction: function (settings: any) {
      //     let distance = settings.value != null ? settings.value.toFixed(3) : 0.000;
      //     let color = "#FFD700";
      //     if (distance <= 1) {
      //       color = "#6B1CB0";
      //     }
      //     else if (distance > 1 && distance <= 1.5) {
      //       color = "#006400";
      //     }
      //     else {
      //       color = "#ff0000";
      //     }
      //     const formatedDate = '<span style="margin-right: 7px; padding: 4px 18px; border-radius: 10px 5px; color: #fff; background: '+ color +'";>' + distance + '</span>';
      //     settings.template = formatedDate;
      //   },
      // },
      { label: 'AvailableDayId', width: '15%', dataField: 'AvailableDayId', align: 'center', columnGroup: 'name', visible: false },
      {
        label: 'Time Start', width: '10%', dataField: 'AvailableStartTime', align: 'center', columnGroup: 'name', formatFunction: function (settings: any) {
          const formatedDate = settings.value != null ? new Smart.Utilities.DateTime(settings.value).toString('hh:mm tt') : "";
          settings.template = formatedDate;
        },
      },
      {
        label: 'Time End', width: '10%', dataField: 'AvailableEndTime', align: 'center', columnGroup: 'name', formatFunction: function (settings: any) {
          const formatedDate = settings.value != null ? new Smart.Utilities.DateTime(settings.value).toString('hh:mm tt') : "";
          settings.template = formatedDate;
        },
      },

    ]

}
