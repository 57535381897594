<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Recent Customers</span>

    <span class="text-muted fw-bold fs-7">More than 500 new customers</span>
  </h3>

  <div class="card-toolbar" data-kt-buttons="true">
    <a
      class="
        btn btn-sm btn-color-muted btn-active btn-active-primary
        active
        px-4
        me-1
      "
      id="kt_charts_widget_5_year_btn"
    >
      Year
    </a>

    <a
      class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1"
      id="kt_charts_widget_5_month_btn"
    >
      Month
    </a>

    <a
      class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4"
      id="kt_charts_widget_5_week_btn"
    >
      Week
    </a>
  </div>
</div>

<div class="card-body">
  <div #chartRef id="kt_charts_widget_5_chart" [style.height]="'350px'">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [dataLabels]="chartOptions.dataLabels"
      [stroke]="chartOptions.stroke"
      [legend]="chartOptions.legend"
      [fill]="chartOptions.fill"
      [states]="chartOptions.states"
      [tooltip]="chartOptions.tooltip"
      [colors]="chartOptions.colors"
      [markers]="chartOptions.markers"
      [plotOptions]="chartOptions.plotOptions"
    ></apx-chart>
  </div>
</div>
