// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//const url = "https://localhost:44361";
const url = "https://devapis.volunteers.llc";
//const url = "https://uatapis.volunteers.llc";

export const environment = {
  production: false,
  buildVersion: 'v 1.0.0.8',
  appVersion: 'v8.0.38',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: url +'/',
  tokenUrl:  url +'/api/token',
  SaveUserAccount:  url +'/api/Account/SaveUserAccount',
  PostForgotPassword:  url +'/api/Account/PostForgotPassword',
  ResetPassword:  url +'/api/Account/ResetPassword',
  GetFormSectionsByFormId: url +'/api/Forms/GetFormSectionsByFormId',
  SaveOrUpdateFormSections: url +'/api/Forms/SaveOrUpdateFormSections',
  GetFormDetailsByFormId: url +'/api/Forms/GetFormDetailsByFormId',
  SaveOrUpdateFormComponents:url +'/api/Forms/SaveOrUpdateFormComponents',
  SaveOrUpdateFormComponentOptions:url +'/api/Forms/SaveOrUpdateFormComponentOptions',
  GetComponentDeatilsByComponentId:url +'/api/Forms/GetComponentDeatilsByComponentId',
  GetSectionDeatilsBySectionId:url +'/api/Forms/GetFormSectionDetailsBySectionId',
  GetFormsByTenantId:url +'/api/Forms/GetFormsByTenantId',
  GetFormComponentOptionsByFormComponentId:url +'/api/Forms/GetFormComponentOptionsByFormComponentId',
  GetComponentOptionDeatilsByComponentOptionId:url +'/api/Forms/GetComponentOptionDeatilsByComponentOptionId',
  GetCRMCustomersByTenantId:url +'/api/Members/GetCRMCustomersByTenantId',
  GetCRMCustomerDetailsById:url +'/api/Members/GetCRMCustomerDetailsById',
  UpdateFormSectionsDisplayOrder:url +'/api/Forms/UpdateFormSectionsDisplayOrder',
  UpdateFormComponentsDisplayOrder:url +'/api/Forms/UpdateFormComponentsDisplayOrder',
  UpdateFormComponentOptionsDisplayOrder:url +'/api/Forms/UpdateFormComponentOptionsDisplayOrder',
  SaveOrUpdateCRMPhones:url +'/api/Members/SaveOrUpdateCRMPhones',
  SaveOrUpdateCRMEmails:url +'/api/Members/SaveOrUpdateCRMEmails',
  SaveOrUpdateCRMAddresses:url +'/api/Members/SaveOrUpdateCRMAddresses',
  GetDSBConstituentsCountbyTenantId:url +'/api/Members/GetDSBConstituentsCountbyTenantId',
  GetCRMPhonesDeatilsByCRMPhoneId:url +'/api/Members/GetCRMPhonesDeatilsByCRMPhoneId',
  GetCRMEmailsDeatilsByCRMEmailId:url +'/api/Members/GetCRMEmailsDeatilsByCRMEmailId',
  GetCRMAddressDeatilsByCRMAddressId:url +'/api/Members/GetCRMAddressesDeatilsByCRMAddressId',
  GetCRMCustomerDetailsByCRMCustomerId:url +'/api/Members/GetCRMCustomerDetailsByCRMCustomerId',
  UpdateCRMCustomerDetailsByCRMCustomerId:url +'/api/Members/UpdateCRMCustomerDetailsByCRMCustomerId',
  GetCRMCustomerAvailableTimeSlots:url +'/api/Availability/GetCRMCustomerAvailableTimeSlots/',
  SaveOrUpdateCRMCustomerAvailableTimeSlots:url +'/api/Availability/SaveOrUpdateCRMCustomerAvailableTimeSlots',
  GetComponentOptionsSelectedValues:url +'/api/Members/GetComponentOptionsSelectedValues',
  GetDSBThisMonthCounts:url +'/api/Dashboard/GetDSBThisMonthCounts',
  GetCRMTimeLinesByCRMCustomerId:url +'/api/TimeLine/GetCRMTimeLinesByCRMCustomerId',
  GetComponentOptionsSelectedValuesDetailsById:url +'/api/Members/GetComponentOptionsSelectedValuesDetailsById',
  SaveOrUpdateComponentOptionsSelectedValues:url +'/api/Members/SaveOrUpdateComponentOptionsSelectedValues',
  GetApplicationLogErrorsByDateRange:url +'/api/ApplicationLog/GetApplicationLogErrorsByDateRange',
  GetApplicationDBErrorsByDateRange:url +'/api/ApplicationLog/GetApplicationDBErrorsByDateRange',
  GetScheduleDetailsByTenantId:url +'/api/Calendar/GetScheduleDetailsByTenantId',
  SaveCRMSchedule:url +'/api/Calendar/SaveOrUpdateCRMSchedule',
  GetScheduleDetailsByCRMScheduleDetailId:url +'/api/Calendar/GetScheduleDetailsByCRMScheduleDetailId',
  GetListOfParticipants:url +'/api/Calendar/GetListOfParticipants',
  GetScheduleDetailsByCRMScheduleIdOccurrence:url +'/api/Calendar/GetScheduleDetailsByCRMScheduleIdOccurrence',
  SaveDeleteCRMSchedule:url +'/api/Calendar/SaveDeleteCRMSchedule',
  GetListOfOrganizations: url +'/api/Organizations/GetListOfOrganizations',
  GetAllMenusByUserIdAndUserRoleId: url +'/api/Account/GetAllMenusByUserIdAndUserRoleId',
  GetVolunteerLocationsByTenantId: url +'/api/Organizations/GetVolunteerLocationsByTenantId',
  GetVolunteersByLocationId: url +'/api/LocationMapping/GetVolunteersByLocationId',
  GetParticipantLocationsByVolunteerId: url +'/api/LocationMapping/GetParticipantLocationsByVolunteerId',
  SentScheduleEmailsToUsers: url + '/api/Account/SentScheduleEmailsToUsers',
  SaveOrUpdateCRMNotes: url + '/api/TimeLine/SaveOrUpdateCRMNotes',
  GetCRMTimeLinesByCRMTimeLineId: url + '/api/TimeLine/GetCRMTimeLinesByCRMTimeLineId',
  GetVolunteersByParticipantLocationId: url +'/api/LocationMapping/GetVolunteersByParticipantLocationId',
  GetParticipantLocationsByLocationId: url +'/api/LocationMapping/GetParticipantLocationsByLocationId',
  GetFetchDatabase:url+'/api/QueryBuilder/FetchDatabase',
  GetConnectDatabase: url + '/api/QueryBuilder/ConnectDatabase',
  GetColumns:url+'/api/QueryBuilder/FetchTableDetails',
  GenerateQuery:url+'/api/QueryBuilder/GenerateQuery',
  Page_Load:url+'/api/QueryBuilder/Page_Load',
  RunQuery:url+'/api/QueryBuilder/Runquery',
  ReportsList:url+'/api/Reports/GetReportQueryByTenantId',
  SaveOrUpdateReportQuery:url+'/api/Reports/SaveOrUpdateReportQuery',
  GetReportQueryByReportQueryId:url+'/api/Reports/GetReportQueryByReportQueryId',
  GetPreMatchingListByTenantId: url + '/api/LocationMapping/GetPreMatchingListByTenantId',
  SaveMatchingStatus: url + '/api/LocationMapping/SaveMatchingStatus',
  GetMatchingStatus: url + '/api/LocationMapping/GetMatchingStatus',
  SaveDeleteMatchingStatusByMatchingId: url + '/api/LocationMapping/SaveDeleteMatchingStatusByMatchingId',
  GetRelationshipRoles: url + '/api/LocationMapping/GetRelationshipRoles',
  GetMembers: url + '/api/LocationMapping/GetMembers',
  GetCustomerRelationshipsByCRMCustomerId: url + '/api/LocationMapping/GetCustomerRelationshipsByCRMCustomerId',
  GetCustomerRelationshipByCRMConstituentRelationshipId: url + '/api/LocationMapping/GetCustomerRelationshipByCRMConstituentRelationshipId',
  SaveCustomerRelationship: url + '/api/LocationMapping/SaveCustomerRelationship',
  DeleteCustomerRelationshipByCRMConstituentRelationshipId: url + '/api/LocationMapping/DeleteCustomerRelationshipByCRMConstituentRelationshipId',
  GetHouseholdRelationshipByCRMCustomerId: url + '/api/LocationMapping/GetHouseholdRelationshipByCRMCustomerId',
  UpdateHeadOfHouseholdStatusByCRMCustomerId: url + '/api/LocationMapping/UpdateHeadOfHouseholdStatusByCRMCustomerId',
  GetCRMCustomersByCustomerType:url +'/api/Members/GetCRMCustomersByCustomerType',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
